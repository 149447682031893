import logo from './logo.svg';
import './App.css';
import 'antd/dist/antd.css';
import './index.js'
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
    SearchOutlined
} from '@ant-design/icons';
import {
    Layout,
    Menu,
    Radio,
    TreeSelect,
    DatePicker,
    Switch,
    AutoComplete,
    Button,
    Cascader,
    Checkbox,
    Card,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    MenuProps,
    Table,
    Modal,
    Upload,
    message,
    Space,

} from 'antd';

import AWS from 'aws-sdk'
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import store from 'store2';
import { apiRoot, findEmailPrefix } from "./Config";
import MailHeader from './component/header'
import moment from 'moment'

const { Search } = Input;
//https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/s3-example-creating-buckets.html
const credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: "us-east-1:d0488ef8-af13-4ba0-9c1c-aebd3215c422" });
const S3_BUCKET = 'newgen-ae-dev';
const REGION = 'us-east-1';
const { TextArea } = Input;


AWS.config.update({
    region: REGION,
    credentials: credentials
}
);

var headers = store.get("pubkit_auth");



const unithead = {
    "Subhash": "subhashp@newgen.co",
    "Gowrishankar": "gowrishankar@newgen.co",
    "Srikanth GS": "srikanth@newgen.co",
    "Jose": "jose@newgen.co",
    "Ashokumar P": "ashokumar@newgen.co",
    "Jo Bottrill": "jo@newgenpublishing.co.uk",
    "Dinesh R": "rdinesh@newgen.co",
    "Hemalatha Meenakshi":"hemalathameenakshi@newgen.co",
    "Krishna Kumar M": "krishnakumar@newgen.co",
    "Ken Stearn": "ken.stearn@spectraglobalsolutions.com",
    "Anupama G": "anupama@newgen.co",
    "Deivanayagam": "deiva@newgen.co",
    "Muralidharan P": "murali@newgen.co",
    "Saravanan": "saran@newgen.co",
    "Maran": "maran@newgen.co",
    "Deepali": "deepali.sawant@newgen.co",
    "Madhavan": "pkmadhu@vijaynicole.co.in",
    "Arnab Ghosh": "arnab.g@newgen.co",
    "Jason": "jason@newgen.co",
    "Kannan NDW": "kannan@newgen.co",
    "Raja Subramanian": "saran@newgen.co",
    "Senthilkumar CFO": "senthil.kumar@newgen.co"
}

const { Header, Sider, Content } = Layout;
const { Option } = Select;
const tempData = {};

export default function DomainMails(props) {
    const [form] = Form.useForm();
    const [collapsed, setCollapsed] = useState(false);
    const params = useParams();
    const [page, setPage] = useState([]);
    const [data, setData] = useState([]);
    const [userList, setUserlist] = useState([{"name": "Anirudh", "id": 'abc123'},{"name": "Saravanan", "id": 'bbc123'}]);
    const [employeeData, setEmployeeData] = useState({});
    const [freelancers, setFreelancers] = useState([]);
    const [newDependentForm, setNewDependentForm] = useState("none");
    const [progress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [emailsForGroup, setEmailsForGroup] = useState([]);
    const [emailsForAlias, setEmailsForAlias] = useState([]);
    const [moreInfo, setMoreInfo] = useState("");
    const [requestedData, setRequestedData] = useState([]);
    const [aliasChildren, setAliasChildren] = useState([]);
    const [aliasChildrenDetaults, setAliasChildrenDetaults] = useState([]);
    const [tempHtml, setTempHtml] = useState();
    const [primaryMailBoxes, setPrimaryMailBoxes] = useState([]);
    const [memberList, setMemberList] = useState([]);
    const [approverList, setApproverList] = useState([]);
    
   
    
    let restFul = axios.create({
        baseURL: apiRoot,
        timeout: 1000
      });
    
      restFul.interceptors.response.use((response) => response, (error) => {
        console.log(error);
        message.info(error.message);
        if(error.response.status==401)
        {
            window.location.href="/";
        }
      });


    useEffect(() => {
        console.log(params);

        if(params.page=="request")
        {
            const apiPendingAxios = () => {
                axios.post(`${apiRoot}office/api/approvedqueries/fuzzyid/bot_status_less_than_equal`, {"where":{"bot-status":"100"}, "limit":100}, {headers: headers}).then(res=>{
                    setRequestedData(res.data);
                }).catch(function(error){
                  console.log(error);
                })
              };
              apiPendingAxios();

            const apiAxios = () => {
                axios.post(`${apiRoot}office/api/approvedqueries/fuzzyid/domain-mail-approvers`, {}, {headers: headers}).then(res=>{
                  setApproverList(res.data);
                }).catch(function(error){
                    if (error.response.status === 401) {
                        window.location.href = "https://misc.newgen.co/";
                    }
                    console.log(error);
                })
              };
              apiAxios();
        }

        if(params.page=="distribution-list")
        {
            var apiUrl = `${apiRoot}office/api/domainmails/find/mail-boxes`;
            axios.get(apiUrl, { headers }).then(res => {
                setEmailsForGroup(res.data);
            });
        }

        if(params.page=="user-list")
        {
            //console.log("authhead",authHeaders);
            var apiUrl = `${apiRoot}office/api/approvedqueries/fuzzyid/newgen-employees`;
            const apiAxios = () => {
                axios.post(apiUrl, data, {headers: headers}).then(res=>{
                  console.log(res.data);
                }).catch(function(error){
                  console.log(error);
                })
              };
              apiAxios();
        }

        if(params.page=="alisas")
        {
            var apiUrl = `${apiRoot}office/api/domainmails/find/mail-boxes`;
            axios.get(apiUrl, { headers }).then(res => {
                setPrimaryMailBoxes(res.data);
            });
        }
    }, []);



    return (
        <Layout>
            <Header
                className="site-layout-background"
                style={{
                    padding: 0,
                }}
            >
                <div className="logo" />
            </Header>
            <Layout className="site-layout">
                <Content
                    className="site-layout-background"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: '100vh',
                    }}
                >

                    {(params.page == "request") && (renderInitialScreen())}
                    {(params.page == "employee") && (renderEmployeeScreen())}
                    {(params.page == "password-recovery") && (renderPasswordResetRequest())}
                    {(params.page == "team") && (renderTeamScreen())}
                    {(params.page == "distribution-list") && (renderDistributionScreen())}
                    {(params.page == "user-list") && (renderUserList())}
                    {(params.page == "alisas") && (renderAlisasScreen())}
                    {(params.page == "delete") && (renderDeleteScreen())}
                    {/* {(params.page == "rename") && (renderRenameScreen())} */}
                    {(params.page == "bu-change") && (renderBuChange())}
                </Content>
            </Layout>
        </Layout>
    );


    function renderUserList(){
        console.log(userList);
        console.log("header",headers);
    }

    function renderInitialScreen() {
        const onUnitHeadChange = (value) => {
            console.log(value);
            
        };

        const onMailforChange = (value) => {
            console.log(value)
        };

        const onFinish = (values) => {
            console.log(values);
            window.location.href = "/hr/emails/" + values.emailfor + "/" + values.unithead
        };

        const onReset = () => {
            form.resetFields();
        };

        const moreInfoOfEmail = (e) =>
        {
            console.log(e);
            if(e==null) { return; }
            var apiUrl = `${apiRoot}office/api/domainmails/moreinfo/`+e.toLowerCase().trim().split("@")[0];
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                if(res.data.email.length===0)
                {
                    setMoreInfo("Record not found");
                    return;
                }
                var html = "<table class='table table-striped'><tbody>";
                for(var prop in res.data.email[0])
                {
                    var values = res.data.email[0][prop]+"";
                    html += "<tr><td>"+prop+"</td><td>"+values.replace(",",", ")+"</td></tr>";
                }
                html += "<tbody></table>";
                setMoreInfo(html);
            });
        }

        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <Form form={form} name="control-hooks" onFinish={onFinish} layout="vertical">
                        <Form.Item
                            name="unithead"
                            label="Enter Business Unit Head Name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Select
                                placeholder=" "
                                onChange={onUnitHeadChange}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {approverList.map((item) => {
                                    return <Option value={item.fuzzy_id}>{item.name + " (Available GApps: "+(item.max_limit-item.total_accounts)+")"}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="emailfor"
                            label="Enter the email for"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                placeholder=" "
                                onChange={onMailforChange}
                                allowClear>
                                <Option value="employee">A DEDICATED MAILBOX FOR EMPLOYEE</Option>
                                <Option value="team">TEAM (MULTIPLE USERS ACCESSING SAME MAILBOX)</Option>
                                <Option value="distribution-list">DISTRIBUTION LIST (ONE ADDRESS MULTIPLE RECIPIENTS)</Option>
                                <Option value="alisas">ALIAS (ADD ADDITIONAL NAMES TO EXISTING MAILBOX)</Option>
                                <Option value="delete">DELETE (DELETE EMAIL FROM THE BUSINESS)</Option>
                                {/* <Option value="rename">RENAME (CHANGE THE EXISTING EMAIL TO A NEW NAME)</Option> */}
                                <Option value="bu-change">MOVE TO ANOTHER BUSINESS UNIT HEAD</Option>
                                <Option value="password-recovery">PASSWORD RECOVERY</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
                
                <div style={{marginTop:"50px"}}>
                    <h3>The following documents may be useful</h3>
                    <div>
                        <Button type="link" onClick={(e) => window.open('https://docs.google.com/presentation/d/1WyAnzxk2EkY0wHFGLPSR-RiX-hOYWT7mWqDXvIv934k/edit#slide=id.g13d60fdf4b3_0_0', "_blank")} size={10}>Types of emails</Button>
                    </div>
                </div>
                
                <Card style={{marginTop:"50px"}}>
                <div>
                    <h6>Get info about email</h6>
                    <div>
                    <Search
                        placeholder="input search text"
                        allowClear
                        onSearch={(e) => moreInfoOfEmail(e)}
                        style={{
                            width: 200,
                        }}
                        />
                    </div>
                    <div style={{marginTop:"30px"}} dangerouslySetInnerHTML={{__html: moreInfo}}>
                    </div>
                </div>
                </Card>

                <Card style={{marginTop:"50px"}}>
                <div>
                <h6>Open Requests</h6>
                <Table rowKey="id" columns={
                    [
                        {
                            key: "requesterName",
                            title: 'Requester',
                            dataIndex: 'requesterName',
                            filterSearch: true,
                            onFilter: (value, record) => record.name.startsWith(value)
                        },
                        {
                            key: "buhead",
                            title: 'Manager',
                            dataIndex: 'buhead',
                            filterSearch: true,
                            onFilter: (value, record) => record.name.startsWith(value)
                        },
                        {
                            key: "email",
                            title: 'Email',
                            dataIndex: 'email'
                        },
                        {
                            key: "type",
                            title: 'Type',
                            dataIndex: 'type',
                            filterSearch: true,
                            onFilter: (value, record) => record.name.startsWith(value)
                        },
                        {
                            key: "exchange",
                            title: 'Exchange',
                            dataIndex: 'exchange',
                            filterSearch: true,
                            onFilter: (value, record) => record.name.startsWith(value)
                        },
                        {
                            key: "action",
                            title: 'Action',
                            dataIndex: 'action'
                        },
                        {
                            title: 'Request',
                            dataIndex: 'request'
                        },
                        {
                            key: "createdAt",
                            title: 'Created At',
                            dataIndex: 'createdAt',
                            render: (record) => {
                                return (
                                  <span>{moment(record).format("lll")}</span>
                                );
                              }
                        }
                    ]
                } dataSource={requestedData} pagination={false} />
                </div>
                </Card>


            </div>
        )
    }



    function renderEmployeeScreen()
    {
        const onFinish = (value) => {
            console.log(value);
            var emailPrefix = findEmailPrefix(value["emailPrefix"]);
            var apiUrl = `${apiRoot}office/api/domainmails/preferred-email?q=`+emailPrefix;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                if(res.data["id"]!==0)
                {
                    message.info('The email address in use already. So, you cannot create again.');
                    return;
                }
                value["buhead"] = params.unithead;
                value["managerFuzzyId"] = params.unithead;
                value["request"] = "EMPLOYEE"
                value["zimbraId"] = "";
                value["email"] = emailPrefix;
                value["emailPrefix"] = emailPrefix;
                if((value["pubkit"]!==undefined) && (value["pubkit"]=="1")) value["pubkit"] = true;
                if((value["pubkit"]!==undefined) && (value["pubkit"]=="0")) value["pubkit"] = false;
                value["primaryEmail"] = "";
                value["botStatus"] = 0;
                value["type"] = "EMPLOYEE";
                value["action"] = "EMPLOYEE MAIL CREATION";
                value["communication"] = "internal";
                if(value["exchange"]==="GAPPS")
                {
                    value["communication"] = "external";
                    value["email"] = findEmailPrefix(value["email"]) + "@newgen.co";
                }
                else
                {
                    value["email"] = findEmailPrefix(value["email"]) + "@nkw.pub";
                }
                value["email"] = value["email"].trim().toLowerCase();
                value["primaryEmail"] = value["email"];

                axios.post(`${apiRoot}office/api/domainmails/add`, value, { headers }).then(res => {
                    console.log(res.data)
                    message.info(res.data.data);
                });
            });


        }
        const onReset = () => {
            form.resetFields();
        };

        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <div className='formheader'>EMPLOYEE DETAILS</div>
                    <Form form={form} name="control-hooks" onFinish={onFinish} layout="vertical" initialValues={{"company":"NKW"}}>
                        <Form.Item
                            label="EMPLOYEE FIRST NAME"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="EMPLOYEE LAST NAME"
                            name="lastName"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="EMPLOYEE ID"
                            name="employeeId"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="ENTER THE PREFERRED EMAIL ADDRESS"
                            name="emailPrefix"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Employee Function"
                            name="function"
                            extra="Examples Project Management, Manager, Accounts"
                            rules={[{required: true}]}
                            >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Client"
                            name="company"
                            rules={[{required: true}]}
                            >
                            <Select placeholder="Select a client" defaultValue={"NKW"}>
                                <Select.Option value="NKW">NKW</Select.Option>
                                <Select.Option value="NPUK">NPUK</Select.Option>
                                <Select.Option value="NNA">NNA</Select.Option>
                                <Select.Option value="NGEU">NGEU</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="exchange" label="Is the new email address required in Gmail or Zimbra?"   rules={[
                                {
                                    required: true,
                                }
                            ]}>
                            <Radio.Group>
                                <Radio value="GAPPS">GAPPS</Radio>
                                <Radio value="ZIMBRA">ZIMBRA</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item name="pubkit" label="Are you going to use PubKit or GApps/Zimbra?" rules={[{required: true}]}>
                            <Radio.Group>
                                <Radio value="0">GApps or Zimbra</Radio>
                                <Radio value="1">PubKit</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button style={{ marginLeft: '10px' }} type="text" htmlType="button" onClick={(e) => {window.location.href = "/hr/emails/request/new"}}>
                                Go back
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
            </div>

        )
    }

    function renderTeamScreen() {

        const onFinish = (value) => {
            console.log(value);
            var emailPrefix = findEmailPrefix(value["emailPrefix"]);
            var apiUrl = `${apiRoot}office/api/domainmails/preferred-email?q=`+emailPrefix;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                if(res.data["id"]!==0)
                {
                    message.info('The email address in use already. So, you cannot create again.');
                    return;
                }
                value["buhead"] = params.unithead;
                value["managerFuzzyId"] = params.unithead;
                value["request"] = "TEAM";
                value["zimbraId"] = "";
                value["email"] = emailPrefix;
                value["emailPrefix"] = emailPrefix;
                if((value["pubkit"]!==undefined) && (value["pubkit"]=="1")) value["pubkit"] = true;
                if((value["pubkit"]!==undefined) && (value["pubkit"]=="0")) value["pubkit"] = false;
                value["primaryEmail"] = "";
                value["type"] = "TEAM";
                value["employeeId"] = "9102083";
                value["name"] = "TEAM";
                value["lastName"] = "";
                value["action"] = "TEAM REQUEST PLACED";
                value["communication"] = "internal";
                if(value["exchange"]=="GAPPS")
                {
                    value["communication"] = "external";
                    value["email"] = value["email"] + "@newgen.co";
                }
                else
                {
                    value["email"] = value["email"] + "@nkw.pub";
                }
                value["email"] = value["email"].trim().toLowerCase();
                value["primaryEmail"] = value["email"];

                axios.post(`${apiRoot}office/api/domainmails/add`, value, { headers }).then(res => {
                    console.log(res.data)
                    message.info(res.data.data);
                });

            });

        }
        const onReset = () => {
            form.resetFields();
        };

        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <div className='formheader'>TEAM DETAILS</div>
                    <Form form={form} name="control-hooks" onFinish={onFinish} layout="vertical" initialValues={{"company":"NKW"}}>
                       
                        <Form.Item
                            label="ENTER THE PREFERRED EMAIL ADDRESS"
                            name="emailPrefix"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Employee Function"
                            name="function"
                            extra="Examples Project Management, Manager, Accounts"
                            rules={[{required: true}]}
                            >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Client"
                            name="company"
                            rules={[{required: true}]}
                            >
                            <Select placeholder="Select a client" defaultValue={"NKW"}>
                                <Select.Option value="NKW">NKW</Select.Option>
                                <Select.Option value="NPUK">NPUK</Select.Option>
                                <Select.Option value="NNA">NNA</Select.Option>
                                <Select.Option value="NGEU">NGEU</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="exchange" label="Is the new email address required in Gmail or Zimbra?"   rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Radio.Group>
                                <Radio value="GAPPS">GAPPS</Radio>
                                <Radio value="ZIMBRA">ZIMBRA</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item name="pubkit" label="Are you going to use PubKit or GApps/Zimbra?" rules={[{required: true}]}>
                            <Radio.Group>
                                <Radio value="0">GApps or Zimbra</Radio>
                                <Radio value="1">PubKit</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button style={{ marginLeft: '10px' }} type="text" htmlType="button" onClick={(e) => {window.location.href = "/hr/emails/request/new"}}>
                                Go back
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
            </div>

        )
    }

    function renderDistributionScreen() {
        tempData["bulk_memeber"] = [];
        const children = [];
        var uniqueEmails = [];
        for (let i = 0; i < emailsForGroup.length; i++)
        {
            uniqueEmails.push(emailsForGroup[i]["primaryEmail"]);
        }
        uniqueEmails = [...new Set(uniqueEmails)]

        for (let i = 0; i < uniqueEmails.length; i++) {
            children.push(<Option key={uniqueEmails[i]}>{uniqueEmails[i]}</Option>);
        }

        const handleChange = (value) =>
        {
            setMemberList([...memberList, value])
        }

        const onFinish = (value) => {
            console.log(value);

            if((value.members===undefined) || (value.members.length===0))
            {
                value.members = tempData["bulk_memeber"];
            }

            if(value.members.length===0)
            {
                message.error("You must have at least a few members");
                return;
            }
            
            var emailPrefix = findEmailPrefix(value["emailPrefix"]);
            value["buhead"] = params.unithead;
            value["managerFuzzyId"] = params.unithead;
            value["request"] = "GROUP";
            value["remarks"] = JSON.stringify({"members":value.members.join(",")});
            value["zimbraId"] = "";
            value["email"] = emailPrefix;
            value["emailPrefix"] = emailPrefix;
            value["type"] = "GROUP";
            value["employeeId"] = "9102083";
            value["name"] = "GROUP";
            value["action"] = "GROUP REQUEST PLACED";
            value["communication"] = "internal";
            if(value["exchange"]==="GAPPS")
            {
                value["communication"] = "external";
                value["email"] = emailPrefix + "@newgen.co";
            }
            else
            {
                value["email"] = emailPrefix + "@nkw.pub";
            }

            axios.post(`${apiRoot}office/api/domainmails/add`, value, { headers }).then(res => {
                console.log(res.data)
                message.info(res.data.data);
            });

        }

        const onReset = () => {
            form.resetFields();
        }

        const createMemberList = (value) =>
        {  
            if(value.target.value===undefined) return;
            var emails = value.target.value+"";
            emails = emails.replaceAll(";", ",").replaceAll(" +", "")
            emails = emails.split(",");
            tempData["bulk_memeber"] = emails;
            console.log(value);
        }

        const findMembers = () =>
        {
            console.log(tempData["primaryEmail"]);
            var emailPrefix = findEmailPrefix(tempData["primaryEmail"]);
            var apiUrl = `${apiRoot}office/api/domainmails/preferred-email?q=`+emailPrefix;
                
            axios.get(apiUrl, { headers }).then(res => {
                
                setMemberList((res.data.members!=null) ? res.data.members : []);
                form.setFieldsValue({
                    members: res.data.members
                })
                if(res.data["id"]==0)
                {
                    message.info('The email address in use already. So, you cannot create again.');
                    return;
                }
            });
        }
        
        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <div className='formheader'>DISTRIBUTION DETAILS</div>
                    <Form form={form} name="control-hooks" onFinish={onFinish} layout="vertical">
                        <Row gutter={[16,16]}>
                            <Col>
                       <Form.Item
                            label="ENTER THE PREFERRED EMAIL ADDRESS"
                            name="emailPrefix"
                            onChange={(e) => {
                                tempData["primaryEmail"] = e.target.value.toLowerCase().trim();
                            }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button onClick={(e)=>findMembers(e)} shape="circle" icon={<SearchOutlined />} />
                            </Form.Item>
                        </Col>
                        </Row>
                        <Form.Item
                            extra="You can add only @newgen.co emails, not nkw.pub"
                            label="Select the recipients email addresses:"
                            name="members">
                            <Select
                                mode="multiple"
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Please select"
                                onChange={value=> handleChange(value)} defaultValue={memberList}>
                                {children}
                                </Select>
                        </Form.Item>
                        <Form.Item name="exchange" label="Is the new email address required in Gmail or Zimbra?" rules={[
                                {
                                    required: true,
                                }
                            ]}>
                            <Radio.Group>
                                <Radio value="GAPPS">GAPPS</Radio>
                                <Radio value="ZIMBRA" disabled>ZIMBRA</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button style={{ marginLeft: '10px' }} type="text" htmlType="button" onClick={(e) => {window.location.href = "/hr/emails/request/new"}}>
                                Go back
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
            </div>

        )
    }



    function renderAlisasScreen() {

        const primaryMailBoxOptions = [];
        var uniqueEmails = [];

        for (let i = 0; i < primaryMailBoxes.length; i++) {
            uniqueEmails.push(primaryMailBoxes[i]["primaryEmail"]);
        }
        uniqueEmails = [...new Set(uniqueEmails)];

        for (let i = 0; i < uniqueEmails.length; i++) {
            primaryMailBoxOptions.push(<Option key={uniqueEmails[i]}>{uniqueEmails[i]}</Option>);
        }

        var aliasChildrens = [];
        var aliasChildrenValues = [];

        const handleChange = (primaryEmail) =>
        {
            var apiUrl = `${apiRoot}office/api/domainmails/find/for-alias?q=`+primaryEmail;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                for(var i=0; i<res.data.length; i++)
                {
                    aliasChildrens.push(<Option key={res.data[i]["email"]}>{res.data[i]["email"]}</Option>);
                    aliasChildrenValues.push(res.data[i]["email"]);
                }
                setAliasChildren(aliasChildrens);
                setAliasChildrenDetaults(aliasChildrenValues);
                setTempHtml(aliasChildrenValues.join(","));
                
            });
        }

        const onAliasSubmitFinish = (value) => {
            console.log(value);

            var emailPrefix = findEmailPrefix(value["primaryEmail"]);
            var emailSuffix = "@nkw.pub";
            if(value["exchange"]==="GAPPS")
            {
                emailSuffix = "@newgen.co";
            }

            var apiUrl = `${apiRoot}office/api/domainmails/preferred-email?q=`+emailPrefix;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                if(res.data["id"]===0)
                {
                    message.info('The email address in use already. So, you cannot create again.');
                    return;
                }
                value["buhead"] = params.unithead;
                value["managerFuzzyId"] = params.unithead;
                value["request"] = "ALIAS";
                value["zimbraId"] = "";
                value["email"] = emailPrefix;
                if((value["pubkit"]!==undefined) && (value["pubkit"]==="1")) value["pubkit"] = true;
                if((value["pubkit"]!==undefined) && (value["pubkit"]==="0")) value["pubkit"] = false;
                value["emailPrefix"] = emailPrefix;
                value["type"] = "ALIAS";
                value["employeeId"] = "9102083";
                value["aliasAddresses"] = value["aliasAddresses"].replaceAll(";", ",");
                value["members"] = value["aliasAddresses"].split(",");
                if(value["members"].length>30)
                {
                    message.info('More than 30 emails are not allowed.');
                    return;
                }
                for(var i=0; i<value["members"].length; i++)
                {
                    var requiredPrefix = value["members"][i].split("@")[0];
                    requiredPrefix = findEmailPrefix(requiredPrefix);
                    value["members"][i] = requiredPrefix + emailSuffix;
                }
                value["name"] = "ALIAS";
                value["action"] = "ALIAS REQUEST PLACED";
                value["communication"] = "internal";
                if(value["exchange"]==="GAPPS")
                {
                    value["communication"] = "external";
                    value["email"] = value["email"] + emailSuffix;
                }
                else
                {
                    value["email"] = value["email"] + emailSuffix;
                }
                value["email"] = value["email"].trim().toLowerCase();
                
                axios.post(`${apiRoot}office/api/domainmails/add`, value, { headers }).then(res => {
                    console.log(res.data)
                    message.info(res.data.data);
                });

            });

        }

        const onReset = () => {
            form.resetFields();
        };

        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <div className='formheader'>ALISAS DETAILS</div>
                    <Form form={form} name="control-hooks" onFinish={onAliasSubmitFinish} layout="vertical">
                       <Form.Item
                            label="You can choose existing mail boxes only"
                            name="primaryEmail"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            style={{
                                width: '100%',
                            }}
                            placeholder="Please select"
                            onChange={handleChange}
                            >
                            {primaryMailBoxOptions}
                            </Select>
                        </Form.Item>

                        <Form.Item extra="More than 30 emails are not allowed" label="Existing aliases to the mailbox selected above">
                            <Input.TextArea value={tempHtml}/>
                        </Form.Item>

                        <Form.Item
                            label="Enter the alias email address"
                            name="aliasAddresses"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="exchange" label="Is the new email address required in Gmail or Zimbra?"   rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Radio.Group>
                                <Radio value="GAPPS">GAPPS</Radio>
                                <Radio value="ZIMBRA" disabled>ZIMBRA</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item name="pubkit" label="Are you going to use PubKit or GApps/Zimbra?" rules={[{required: true}]}>
                            <Radio.Group>
                                <Radio value="0">GApps or Zimbra</Radio>
                                <Radio value="1">PubKit</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button style={{ marginLeft: '10px' }} type="text" htmlType="button" onClick={(e) => {window.location.href = "/hr/emails/request/new"}}>
                                Go back
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
            </div>

        )
    }



    function renderDeleteScreen() {

        const onDeleteFinish = (value) => {
            console.log(value);

            var emailPrefix = findEmailPrefix(value["emailPrefix"]);

            var apiUrl = `${apiRoot}office/api/domainmails/preferred-email?q=`+emailPrefix;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                if(res.data["id"]===0)
                {
                    message.info('The email address is not available in the system. So, delete request is inappropriate.');
                    return;
                }
                if(res.data["status"].toUpperCase()!=="ACTIVE")
                {
                    message.info('The email address is inactive in the system. So, delete request is inappropriate.');
                    return;
                }
                value["buhead"] = params.unithead;
                value["managerFuzzyId"] = params.unithead;
                value["request"] = "DELETE";
                value["email"] = emailPrefix;
                value["status"] = "DELETE";
                value["action"] = "DELETE";
                value["botStatus"] = 0;
                console.log(headers);
                axios.post(`${apiRoot}office/api/domainmails/delete/`+res.data["fuzzyId"], value, { headers }).then(res => {
                    console.log(res.data)
                    message.info("Your request has been placed. It will be addressed in 24 hours.");
                });
            });

        }

        const onReset = () => {
            form.resetFields();
        };

        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <div className='formheader'>EMAIL DELETE REQUEST</div>
                    <Form form={form} name="control-hooks" onFinish={onDeleteFinish} layout="vertical">
                       <Form.Item
                            label="Enter the email address to delete"
                            name="emailPrefix"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                      
                      

                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button style={{ marginLeft: '10px' }} type="text" htmlType="button" onClick={(e) => {window.location.href = "/hr/emails/request/new"}}>
                                Go back
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
            </div>

        )
    }





    function renderPasswordResetRequest()
    {
        const onPasswordResetRequestFinish = (value) => {
            console.log(value);
            value["email"] = value["email"].trim().toLowerCase();
            var emailPrefix = findEmailPrefix(value["email"]);
            value["emailPrefix"] = emailPrefix;

            var apiUrl = `${apiRoot}office/api/domainmails/preferred-email?q=`+emailPrefix;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                if(res.data["id"]===0)
                {
                    message.info('The email address is not available');
                    return;
                }
                value["buhead"] = params.unithead;
                value["managerFuzzyId"] = params.unithead;
                value["request"] = "PASSWORD_RESET";
                value["status"] = "ACTIVE";
                value["action"] = "PASSWORD_RESET";
                value["botStatus"] = 0;
                console.log(headers);
                axios.post(`${apiRoot}office/api/domainmails/add`, value, { headers }).then(res => {
                    console.log(res.data)
                    message.info(res.data.data);
                });
            });

        }

        const onReset = () => {
            form.resetFields();
        };

        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <div className='formheader'>EMAIL PASSWORD RESET REQUEST</div>
                    <Form form={form} name="control-hooks" onFinish={onPasswordResetRequestFinish} layout="vertical">
                       <Form.Item
                            label="Enter the email address to reset the password"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                      
                      

                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button style={{ marginLeft: '10px' }} type="text" htmlType="button" onClick={(e) => {window.location.href = "/hr/emails/request/new"}}>
                                Go back
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
            </div>

        )
    }


    function renderBuChange()
    {
        const onFinish = (value) => {
            console.log(value);
            var emailPrefix = findEmailPrefix(value["emailPrefix"]);
            var apiUrl = `${apiRoot}office/api/domainmails/preferred-email?q=`+emailPrefix;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                if(res.data["id"]==0)
                {
                    message.info('The email address is not in use');
                    return;
                }
                value["buhead"] = params.unithead;
                value["managerFuzzyId"] = params.unithead;
                value["request"] = "BUCHANGE";
                value["email"] = emailPrefix;
                value["remarks"] = '{"buhead":\"'+value["newBuhead"]+'\"}';
                value["action"] = "BUCHANGE";
                value["botStatus"] = 0;
                console.log(headers);
                axios.post(`${apiRoot}office/api/domainmails/data-update/`+res.data["fuzzyId"], value, { headers }).then(res => {
                    console.log(res.data)
                    message.info(res.data);
                });
            });

        }

        const onReset = () => {
            form.resetFields();
        };

        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <div className='formheader'>MOVE EMAIL TO ANOTHER BU</div>
                    <Form form={form} name="control-hooks" onFinish={onFinish} layout="vertical">
                       <Form.Item
                            label="Enter the email address to modify BU"
                            name="emailPrefix"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="newBuhead"
                            label="Enter Business Unit Head Name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {Object.keys(unithead).map((item) => {
                                    return <Option value={item}>{item}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button style={{ marginLeft: '10px' }} type="text" htmlType="button" onClick={(e) => {window.location.href = "/hr/emails/request/new"}}>
                                Go back
                            </Button>

                        </Form.Item>
                    </Form>
                </div>
            </div>

        )
    }


    function renderRenameScreen()
    {
        const onFinishRename = (value) => {
            console.log(value);
            var emailPrefix = findEmailPrefix(value["emailPrefix"]);
            var apiUrl = `${apiRoot}office/api/domainmails/preferred-email?q=`+emailPrefix;
            axios.get(apiUrl, { headers }).then(res => {
                console.log(res.data);
                if(res.data["id"]===0)
                {
                    message.info('The email address is not in use');
                    return;
                }
                value["new_name"] = value["new_name"].toLowerCase();
                if((res.data["email"].contains("@newgen.co")===false) || (value["new_name"].contains("@newgen.co")==false))
                {
                    message.info('Only newgen.co emails can be renamed');
                    return;
                }
                value["request"] = "RENAME";
                value["email"] = emailPrefix;
                value["remarks"] = {};
                value["remarks"]["newName"] = value["new_name"];
                value["action"] = "RENAME";
                value["botStatus"] = 0;
                console.log(headers);
                axios.post(`${apiRoot}office/api/domainmails/data-update/`+res.data["fuzzyId"], value, { headers }).then(res => {
                    console.log(res.data)
                    message.info(res.data);
                });
            });

        }

        const onReset = () => {
            form.resetFields();
        };

        return (
            <div className='domainmail-continer'>
                <MailHeader />
                <div className='requestform'>
                    <div className='formheader'>RENAME THE EXISTING EMAIL BOX</div>
                    <Form form={form} name="control-hooks" onFinish={onFinishRename} layout="vertical">
                       <Form.Item
                            label="Enter the existing email address"
                            name="emailPrefix"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="new_name"
                            label="Enter the new name to apply"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button style={{ marginLeft: '10px' }} htmlType="button" onClick={onReset}>
                                Reset
                            </Button>
                            <Button style={{ marginLeft: '10px' }} type="text" htmlType="button" onClick={(e) => {window.location.href = "/hr/emails/request/new"}}>
                                Go back
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>

        )
    }

};
